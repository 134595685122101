<template>
    <template v-if="!showLoaderSending">
        <template v-if="$store.state.mode === 'all'">
            <pre>{{ lists }}</pre>
        </template>
        <!--div class="app__block-title">
            <h1 class="d-flex align-items-center mb-5">
                <BlockActionsBond
                    :key="`bond-item-${bond.id}`"
                    :bond="bond"
                    :lists="lists"
                    hideIndex
                    @getWatchLists="getWatchLists"
                />
                {{ bond.info.short_name }}
            </h1>
        </div-->
        <div class="bond__info mb-3 pt-3">
            <div class="bond__info-block row">
                <div class="bond__info-block-item col-12 col-xl-8 col-xxl-6 mb-4">
                    <b-card
                        class="shadow h-100"
                        bodyClass="bond__info-block-item-inner"
                    >
                        <div class="bond__info-block-item-title m--main d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <BlockActionsBond
                                    :key="`bond-item-${bond.id}`"
                                    :bond="bond"
                                    :lists="lists"
                                    hideIndex
                                    @getWatchLists="getWatchLists"
                                />
                                {{ bond.info.short_name }}
                            </div>
                            <span class="ps-2">
                                {{ bond.actual_price?.price_percent ? $helpers.toPrice(bond.actual_price.price_percent, { sign: '%', pointer: ',' }) : '—' }}
                                ·
                                <span>{{ bond.actual_price?.price ? $helpers.toPrice(bond.actual_price.price, { sign: bond.currency.symbol, pointer: ',' }) : '—' }}</span>
                            </span>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mt-auto">
                            <div class="pe-3">
                                <template
                                    v-if="bond.info.has_default !== 'not'"
                                >
                                    <b-icon-exclamation-triangle
                                        :id="`popover-has_default-${bond.id}`"
                                        :class="['h4 me-2', bond.info.has_default === 'total' ? 'text-danger' : 'text-warning']"
                                    />
                                    <b-popover
                                        custom-class="shadow"
                                        :target="`popover-has_default-${bond.id}`"
                                        placement="top"
                                        triggers="hover"
                                    >
                                        <div
                                            v-html="bond.info.has_default_detail"
                                        />
                                    </b-popover>
                                </template>
                                <strong>ISIN</strong> {{ bond.isin }} / <strong>Код</strong> {{ bond.ticker }}
                            </div>
                            <div class="ms-auto">% от номинала / цена</div>
                        </div>
                    </b-card>
                </div>
                <BlockInfo
                    title="Сумма купона без учета налога"
                    :value="bond.info?.coupon_amount || '—'"
                    :valueToPrice="bond.info?.coupon_amount ? true : false"
                    :valueUseCurrency="bond.currency"
                    classMain="bond__info-block-item"
                    classColor=""
                    helper="bond_value"
                />
                <BlockInfo
                    title="Дата следующего купона"
                    :value="`${(bond.info?.coupon_date) ? $helpers.formatDate(new Date(bond.info?.coupon_date), 'DD.MM.YYYY') : '—'}`"
                    classMain="bond__info-block-item"
                    classColor=""
                    helper="bond_pay_date"
                />
                <BlockInfo
                    title="Общая доходность (в год погашения)"
                    :value="bond.actual_price?.total_income || '—'"
                    :valueToPercent="bond.actual_price?.total_income ? true : false"
                    classMain="bond__info-block-item"
                    classColor=""
                    helper="bond_total_income"
                />
                <BlockInfo
                    title="Доход 1"
                    :value="bond.actual_price?.year_income || '—'"
                    :valueToPercent="bond.actual_price?.year_income ? true : false"
                    classMain="bond__info-block-item"
                    classColor=""
                    helper="bond_year_income"
                />
                <BlockInfo
                    title="Доход 2 (в год погашения)"
                    :value="bond.actual_price?.close_income || '—'"
                    :valueToPercent="bond.actual_price?.close_income ? true : false"
                    classMain="bond__info-block-item"
                    classColor=""
                    helper="bond_close_income"
                />
                <BlockInfo
                    title="Дата погашения"
                    :value="`${(bond.info.maturity_date) ? $helpers.formatDate(new Date(bond.info.maturity_date), 'DD.MM.YYYY') : '—'}`"
                    classMain="bond__info-block-item"
                    classColor=""
                    helper="bond_maturity_date"
                />
            </div>
        </div>
        <div class="bond__info mb-2">
            <div class="bond__info-block row">
                <div
                    v-if="bond.info.note"
                    class="col-12 mb-3"
                >
                    <div class="bond__info-block-item mb-4">
                        <b-card
                            class="shadow px-2"
                            bodyClass="bond__info-block-item-inner"
                        >
                            <h5>Об инструменте</h5>
                            <p class="mb-1">{{ bond.info.note }}</p>
                        </b-card>
                    </div>
                </div>
                <div class="col-12 col-xl-6 mb-4">
                    <div class="bond__info-block-item mb-4">
                        <b-card
                            class="shadow"
                            bodyClass="bond__info-block-item-inner"
                        >
                            <div class="bond__info-block-item-title d-flex w-100 mb-2">
                                <div class="pe-3">Текущий номинал</div>
                                <div class="ms-auto"><strong>{{ bond.actual_price?.nominal ? $helpers.toPrice(bond.actual_price?.nominal, { sign: bond.currency.symbol, pointer: ',' }) : '—' }}</strong></div>
                            </div>
                            <div class="bond__info-block-item-title d-flex w-100 mb-2">
                                <div class="pe-3">Цена последней сделки предыдущего дня, % к номиналу</div>
                                <div class="ms-auto text-nowrap"><strong>{{ bond.actual_price?.previous_price_percent ? $helpers.toPrice(bond.actual_price?.previous_price_percent, { sign: '%', pointer: ',' }) : '—' }}</strong></div>
                            </div>
                            <div class="bond__info-block-item-title d-flex w-100 mb-2">
                                <div class="pe-3">Дата начала торгов</div>
                                <div class="ms-auto"><strong>{{ `${(bond.info.issue_date) ? $helpers.formatDate(new Date(bond.info.issue_date), 'DD.MM.YYYY') : '—'}` }}</strong></div>
                            </div>
                            <div class="bond__info-block-item-title d-flex w-100 mb-2">
                                <div class="pe-3">Ставка купона, %</div>
                                <div class="ms-auto"><strong>{{ bond.info?.coupon_rate ? $helpers.toPrice(bond.info?.coupon_rate, { sign: '%', pointer: ',' }) : '—' }}</strong></div>
                            </div>
                            <div class="bond__info-block-item-title d-flex w-100 mb-2">
                                <div class="pe-3">НКД</div>
                                <div class="ms-auto"><strong>{{ bond.actual_price?.aci_value ? $helpers.toPrice(bond.actual_price?.aci_value, { sign: bond.currency.symbol, pointer: ',' }) : '—' }}</strong></div>
                            </div>
                            <div class="bond__info-block-item-title d-flex w-100 mb-2">
                                <div class="pe-3">Сумма купона</div>
                                <div class="ms-auto"><strong>{{ bond.info.coupon_amount ? $helpers.toPrice(bond.info.coupon_amount, { sign: '₽', pointer: ',' }) : '—' }}</strong></div>
                            </div>
                            <div class="bond__info-block-item-title d-flex w-100 mb-2">
                                <div class="pe-3">Дата выплаты следующего купона</div>
                                <div class="ms-auto"><strong>{{ `${(bond.info?.coupon_date) ? $helpers.formatDate(new Date(bond.info?.coupon_date), 'DD.MM.YYYY') : '—'}` }}</strong></div>
                            </div>
                            <div class="bond__info-block-item-title d-flex w-100 mb-2">
                                <div class="pe-3">Дней после выплаты купона</div>
                                <div class="ms-auto"><strong>{{ bond.info?.coupon_days_after }}</strong></div>
                            </div>
                            <div class="bond__info-block-item-title d-flex w-100 mb-2">
                                <div class="pe-3">Дней до выплаты следующего купона</div>
                                <div class="ms-auto"><strong>{{ bond.info?.coupon_days_until_next }}</strong></div>
                            </div>
                            <div class="bond__info-block-item-title d-flex w-100 mb-2">
                                <div class="pe-3">Частота выплаты купонов, раз в год</div>
                                <div class="ms-auto"><strong>{{ bond.info.coupon_frequency }}</strong></div>
                            </div>
                            <div class="bond__info-block-item-title d-flex w-100 mb-2">
                                <div class="pe-3">Лет до погашения</div>
                                <div class="ms-auto"><strong>{{ $helpers.toPrice(bond.info.maturity_years, { pointer: ',' }) }}</strong></div>
                            </div>
                            <div class="bond__info-block-item-title d-flex w-100 mb-2">
                                <div class="pe-3">Доходность к погашению (YTM)</div>
                                <div class="ms-auto"><strong>{{ $helpers.toPrice(bond.actual_price.yield_to_maturity, { pointer: ',' }) }}</strong></div>
                            </div>
                            <div class="bond__info-block-item-title d-flex w-100 mb-2">
                                <div class="pe-3">Дюрация, лет</div>
                                <div class="ms-auto"><strong>{{ bond.actual_price?.duration ? $helpers.toPrice(bond.actual_price?.duration, { pointer: ',' }) : '—' }}</strong></div>
                            </div>
                        </b-card>
                    </div>
                    <div v-if="bond.emitter" class="bond__info-block-item mb-4">
                        <b-card
                            class="shadow"
                            bodyClass="bond__info-block-item-inner"
                        >
                            <div class="bond__info-block-item-title d-flex mb-1">
                                Название компании (эмитент)
                                <span class="ms-auto">Рейтинг <strong>{{ bond.emitter.rating_detail }}</strong></span>
                            </div>
                            <div class="bond__info-block-item-title h2 d-flex w-100 mb-3">
                                {{ bond.emitter.name }}
                            </div>
                            <div
                                v-if="bond.emitter.country_name || bond.emitter.inn || bond.emitter.foundation_date"
                                class="row"
                            >
                                <div class="bond__info-block-item-title mb-2">
                                    <template v-if="bond.emitter.country_name">
                                        <strong>{{ bond.emitter.country_name }}</strong> {{ bond.emitter.inn || bond.emitter.foundation_date ? '· ' : '' }}
                                    </template>
                                    <template v-if="bond.emitter.inn">
                                        <strong>ИНН</strong> {{ bond.emitter.inn }} {{ bond.emitter.status_detail ? '· ' : '' }}
                                    </template>
                                    <template v-if="bond.emitter.status">
                                        <strong>Статус</strong> <span :class="`m--${bond.emitter.status}`">{{ bond.emitter.status_detail }} {{ bond.emitter.foundation_date ? '· ' : '' }}</span>
                                    </template>
                                    <template v-if="bond.emitter.foundation_date">
                                        <strong>Год основания</strong> {{ bond.emitter.foundation_date }}
                                    </template>
                                </div>
                            </div>
                            <div
                                v-if="bond.emitter.branch"
                                class="bond__info-block-item-titlemb-2"
                            >
                                Отрасль
                                <Helper
                                    id="popover-bond-emitter"
                                    helper="bond_emitter"
                                />
                                <strong>{{ bond.emitter.branch }}</strong>
                            </div>
                            <div
                                v-if="bond.emitter.description"
                                class="mb-2"
                            >
                                {{ bond.emitter.description }}
                            </div>
                        </b-card>
                    </div>
                </div>
                <div class="col-12 col-xl-6 mb-4">
                    <b-card
                        class="bond__info-block-item mb-4 shadow"
                        bodyClass="bond__info-block-item-inner"
                    >
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Полное наименование</div>
                            <div class="ms-auto text-end"><strong>{{ bond.name }}</strong></div>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Номер государственной регистрации</div>
                            <div class="ms-auto text-end"><strong>{{ bond.info.reg_number }}</strong></div>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Иностранное название</div>
                            <div class="ms-auto text-end"><strong>{{ bond.info.lat_name }}</strong></div>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Вид/категория ценной бумаги</div>
                            <div class="ms-auto text-end"><strong>{{ bond.info.security_type }}</strong></div>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Бумаги для квалифицированных инвесторов</div>
                            <div class="ms-auto text-end"><strong>{{ bond.qualified ? 'Да' : 'Нет' }}</strong></div>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Особенности номинала</div>
                            <div class="ms-auto text-end"><strong>{{ bond.info.nominal_feature || 'Нет' }}</strong></div>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Особенности купона</div>
                            <div class="ms-auto text-end"><strong>{{ bond.info.coupon_feature || 'Нет' }}</strong></div>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Первоначальная номинальная стоимость</div>
                            <div class="ms-auto text-end"><strong>{{ $helpers.toPrice(bond.info.initial_nominal, { pointer: ',' }) }}</strong></div>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Валюта номинала</div>
                            <div class="ms-auto text-end"><strong>{{ bond.currency.iso_name }}</strong></div>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Размер лота</div>
                            <div class="ms-auto text-end"><strong>{{ bond.lot }}</strong></div>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Дата оферты</div>
                            <div class="ms-auto text-end"><strong>{{ `${(bond.info.offer_date) ? $helpers.formatDate(new Date(bond.info.offer_date), 'DD.MM.YYYY') : '—'}` }}</strong></div>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Уровень листинга</div>
                            <div class="ms-auto text-end"><strong>{{ bond.info.list_level }}</strong></div>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Объем выпуска</div>
                            <div class="ms-auto text-end"><strong>{{ $helpers.toPrice(bond.info.issue_size_plan) }}</strong></div>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Количество ценных бумаг в обращении</div>
                            <div class="ms-auto text-end"><strong>{{ $helpers.toPrice(bond.info.issue_size) }}</strong></div>
                        </div>
                        <div class="bond__info-block-item-title d-flex w-100 mb-2">
                            <div class="pe-3">Идентификатор режима торгов</div>
                            <div class="ms-auto text-end"><strong>{{ bond.class_code }}</strong></div>
                        </div>
                    </b-card>
                    <b-card
                        class="bond__info-block-item mb-4 shadow"
                        bodyClass="bond__info-block-item-inner"
                    >
                        <div class="bond__info-block-item-title h2 d-flex w-100 mb-3">
                            Текущая доля {{ bond.info.short_name }} на ваших брокерских счетах
                        </div>
                        <div
                            v-for="item in bond.portfolio_shares"
                            :key="`share-${item.portfolio}`"
                            class="bond__info-block-item-title d-flex w-100 mb-2"
                        >
                            <div class="pe-3">
                                <router-link
                                    :to="{ name: 'account-info', params: { portfolioId: item.portfolio } }"
                                    class="m--underline"
                                >
                                    {{ item.portfolio_name }}
                                </router-link>
                            </div>
                            <div class="ms-auto text-end">
                                <strong>
                                    {{ $helpers.toPrice(item.share_in_portfolio, { pointer: ',', sign: '%' }) }} ({{ $helpers.toPrice(item.actual_sum, { pointer: ',', sign: bond.currency.symbol }) }})
                                </strong>
                            </div>
                        </div>
                    </b-card>
                </div>
            </div>
        </div>
        <div class="bond__coupons">
            <h2 class="mb-4">
                График выплаты купонов и амортизации
                <Helper
                    id="popover-bond-coupon-chart"
                    helper="bond_coupon_chart"
                    classModifier="m--dark"
                    sup
                />
            </h2>
            <div class="filters bond__coupons-filters form mb-4 d-flex flex-wrap">
                <div class="mb-3 me-3 mt-auto me-auto">
                    <b-card class="filters__item shadow">
                        <b-form-checkbox variant="success" v-model="tableDataFilters.only_next" name="check-button" switch>
                            Показать только будущие купоны
                        </b-form-checkbox>
                    </b-card>
                </div>
                <BlockFilterTable
                    :tableHeader="tableHeader"
                    :tableHeaderName="tableHeaderName"
                    :tableFilters="tableFilters"
                    @changeFilterTable="changeFilterTable"
                />
            </div>
            <b-card
                class="bond__coupons-table shadow mb-5"
                bodyClass="p-1"
            >
                <b-table
                    :fields="$helpers.prepareTableFields(this.tableHeader, this.tableFilters)"
                    :items="prepareTableData()"
                    sticky-header
                    responsive
                    hover
                    class="table__data b-table-sticky-header"
                >
                    <template #head()="data">
                        <TableHeaderSlot
                            :data="data"
                            :currentSorting="currentSorting"
                            @changeSorting="changeSorting"
                        />
                    </template>
                    <template #cell(start_date)="data">
                        {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '—' }}
                    </template>
                    <template #cell(pay_date)="data">
                        {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '—' }}
                    </template>
                    <template #cell(record_date)="data">
                        {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '—' }}
                    </template>
                    <template #cell(nominal)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',', zero_kop: 1 }) : '—' }}
                        </div>
                    </template>
                    <template #cell(amortization_amount)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(amortization_rate)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(rate)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(amount)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                </b-table>
                <BlockNavigateTable
                    :totalRows="currentTotalRows"
                    :currentPage="currentPage"
                    :currentPerPage="currentPerPage"
                    @changeNavigateTable="changeNavigateTable"
                />
                <b-overlay
                    :show="showLoaderSending"
                    variant="transparent"
                    no-wrap
                    spinner-variant="success"
                />
            </b-card>
        </div>
        <div class="bond__chart">
            <h2 class="mb-4">
                История цены {{ bond.info.short_name }}
                <Helper
                    id="popover-bond-chart"
                    helper="bond_chart"
                    classModifier="m--dark"
                    sup
                />
            </h2>
            <b-card class="shadow mb-5 py-4">
                <template v-if="!showLoaderCharts">
                    <highcharts
                        :id="`chart-bond-${bond.slug}`"
                        :constructorType="'stockChart'"
                        :options="chartOptions || {}"
                    >
                    </highcharts>
                </template>
                <b-overlay
                    :show="showLoaderCharts"
                    variant="transparent"
                    no-wrap
                    spinner-variant="success"
                />
            </b-card>
        </div>
        <template v-if="$store.state.mode === 'all'">
            <pre>{{ bond }}</pre>
            <pre>{{ coupons }}</pre>
        </template>
    </template>
    <template v-else>
        <div class="app__loader">
            <b-spinner
                variant="success"
                class="me-3"
            />
            <span class="text-success">Загрузка данных ...</span>
        </div>
    </template>
</template>

<script>
    import { bondHistory } from "@/settings/charts";
    import { fieldNames } from "@/settings/tables";

    import { app } from "@/services";
    import BlockInfo from "@/components/block-info";
    import BlockActionsBond from "@/components/block-actions-bond";
    import BlockFilterTable from "@/components/block-filter-table";
    import BlockNavigateTable from "@/components/block-navigate-table";
    import TableHeaderSlot from "@/components/table-header-slot";
    import Helper from "@/components/helper";
    export default {
        name: 'bond',
        components: {
            BlockInfo,
            BlockActionsBond,
            BlockFilterTable,
            BlockNavigateTable,
            TableHeaderSlot,
            Helper
        },
        props: {
            slug: {
                type: String,
                default() { return null; }
            },
        },
        data() {
            return {
                bond: {},
                coupons: [],
                lists: [],
                chartOptions: {},
                tableFilters: [],
                tableDataFilters: {
                    only_next: true,
                },
                tableHeaderName: 'bondCouponItem',
                tableHeader: fieldNames.bondCouponItem,
                currentSorting: {
                    key: 'start_date',
                    direction: 'asc'
                },
                currentPerPage: 50,
                currentPage: 1,
                showLoaderSending: false,
                showLoaderCharts: false,
            };
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        created() {
            this.getBond();
        },
        mounted() {
            /*
            this.$breadcrumbs.value.at(-2).label = (this.portfolioType === 'account') ? this.portfolio.name : (this.portfolio.trust_name || this.portfolio.name);
            */
            this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                if (this.userSettings.tableHeader?.[this.tableHeaderName]) {
                    if (this.userSettings.tableHeader[this.tableHeaderName].indexOf(key) !== -1) this.tableFilters.push(key);
                } else {
                    if (!this.tableHeader[key].hideDefault) this.tableFilters.push(key);
                }
            });
        },
        methods: {
            getWatchLists() {
                let params = {
                    instrument_type: 'bond',
                    currency: this.bond.currency.id
                };
                this.showLoaderSending = true;
                app.getWatchLists(params).then(res => {
                    if (!res.error) {
                        this.lists = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            getBond() {
                this.showLoaderSending = true;
                app.getBond(this.slug).then(res => {
                    if (!res.error) {
                        this.bond = res;
                        this.$breadcrumbs.value.at(-1).label = `Облигация ${this.bond.name}`;

                        console.log(res);
                        this.getWatchLists();
                        this.getBondCoupons();
                        this.getBondHistoryChart();
                        //this.showLoaderSending = false;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    if (err.response.status === 404) {
                        this.$router.push({ name: 'page404' });
                    } else {
                        this.$store.dispatch('showError', err);
                    }
                    //this.showLoaderSending = false;
                    console.error(err);
                });
            },
            getBondCoupons() {
                this.showLoaderSending = true;
                app.getBondCoupons(this.slug).then(res => {
                    if (!res.error) {
                        this.coupons = res;
                        console.log(res);
                        this.showLoaderSending = false;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            prepareTableData() {
                let list = [...this.coupons];
                if (list.length) {
                    list = this.filterTableData(list);
                    this.currentTotalRows = list.length;
                    if (this.currentSorting.key) {
                        list = list.sort((a, b) => {
                            if (a[this.currentSorting.key] > b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? 1 : -1;
                            if (a[this.currentSorting.key] < b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }
                    if (this.currentPerPage) {
                        list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                    }
                }
                return list;
            },
            filterTableData(data) {
                let list = [...data];
                list = list.filter(item => {
                    item._rowVariant = new Date(item.pay_date) >= new Date() ? '' : ' text-danger';
                    return true;
                });
                /*
                list = list.filter(item => {
                    //if (this.currentDataFilter === 'all') {
                    //    return true;
                    //}
                    //console.log(new Date(item.date), new Date(this.dateRange.start), new Date(this.dateRange.end));
                    if (new Date(item.date) >= new Date(this.dateRange.start) && new Date(item.date) <= new Date(this.dateRange.end)) {
                        return true;
                    }
                    return false;
                });
                */
                /* Фильтр - Показать периоды без операций */
                if (this.tableDataFilters.only_next) {
                    list = list.filter(item => {
                        return new Date(item.pay_date) >= new Date();
                    });
                }
                return list;
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
            },
            changeFilterTable(filter) {
                this.tableFilters = filter;
            },
            changeNavigateTable(page, perPage) {
                this.currentPage = page;
                this.currentPerPage = perPage;
            },
            getBondHistoryChart() {
                this.showLoaderCharts = true;
                app.getBondHistory(this.slug).then(res => {
                    if (!res.error) {
                        console.log(res);
                        this.showLoaderCharts = false;
                        this.chartOptions = bondHistory;
                        this.chartOptions.tooltip.valueSuffix = '%';
                        this.chartOptions.series[0].name = this.chartOptions.series[0].nameTemplate;
                        this.chartOptions.series[0].data = res.map(item => {
                            return [
                                new Date(item.date).getTime(),
                                +item.price_percent,
                            ];
                        });
                        this.chartOptions.series[1].name = this.chartOptions.series[1].nameTemplate;
                        this.chartOptions.series[1].data = res.map(item => {
                            return [
                                new Date(item.date).getTime(),
                                +item.year_income,
                            ];
                        });
                        this.chartOptions.series[2].name = this.chartOptions.series[2].nameTemplate;
                        this.chartOptions.series[2].data = res.map(item => {
                            return [
                                new Date(item.date).getTime(),
                                +item.close_income,
                            ];
                        });
                        this.chartOptions.series[3].name = this.chartOptions.series[3].nameTemplate;
                        this.chartOptions.series[3].data = res.map(item => {
                            return [
                                new Date(item.date).getTime(),
                                +item.total_income,
                            ];
                        });
                        this.chartOptions.series[4].name = this.chartOptions.series[4].nameTemplate;
                        this.chartOptions.series[4].data = res.map(item => {
                            return [
                                new Date(item.date).getTime(),
                                +item.value,
                            ];
                        });
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    this.showLoaderCharts = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            changeTab(tab) {
                console.log('changeTab', tab);
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
